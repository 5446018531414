// ---------- import external dependencies -------------
import { useMutation, useQuery } from "react-query";
// import { toast } from "react-toastify";

// ----------- import internal dependencies -----------
import { instance } from "../../api/instance";

function useAuth() {
  /**
   * Handle merchant registration;
   */
  const registerMerchant = useMutation((data) => {
    return instance({
      url: "/register",
      method: "post",
      data: data,
    });
  });

  /**
   * Handle merchant login
   */
  const loginMerchant = useMutation((data) => {
    return instance({
      url: "/login",
      method: "post",
      data,
    });
  });

  /**
   * Handle logout
   */
  const logout = useMutation((data) => {
    return instance({
      url: "/logout",
      method: "post",
      data,
    });
  });

  const forgotPassword = useMutation((data) => {
    return instance({
      url: "/password/forgot",
      method: "post",
      data,
    });
  });

  const newPassword = useMutation((data) => {
    return instance({
      url: "/password/reset",
      method: "post",
      data,
    });
  });

  const socialMediaLogin = useMutation((data) => {
    return instance({
      url: "/login/loginFromSocial",
      method: "post",
      data,
    });
  });

  const verifyEmail = useMutation((data) => {
    return instance({
      url: "/email/verify",
      method: "post",
      data,
    });
  });

  const sendOtp = useMutation((data) => {
    return instance({
      url: "/sendLoginOTP",
      method: "post",
      data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  });

  const loginWithOtp = useMutation((data) => {
    return instance({
      url: "/loginWithOTP",
      method: "post",
      data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  });

  const resendVerificationEmail = useQuery(
    "resendVerficationEmail",
    async (type) => {
      return instance({
        url: `/email/resend`,
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
      enabled: false // disable this query from automatically running

    }
  );

  const signout = ()=> {
    localStorage.clear();
    //toast.success('Signin to continue');
  }

  const refreshToken = useMutation(() => {
    return instance({
      url: "/refreshToken",
      method: "post",
    });
  });

 const authCheckState = () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        signout();
      } else {
        const expirationDate = new Date(localStorage.getItem("expirationDate"));

        if (expirationDate <= new Date()) {
          signout();
        }else{
          checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000)
        }

    };
  };



  const checkAuthTimeout = (expirationTime) => {
   try {

    setTimeout(async() => {
      const response = await refreshToken.mutateAsync();
      const expirationDate = new Date(
        new Date().getTime() + response?.data?.token?.expires_in * 1000
      );
      localStorage.setItem("accessToken", response?.data?.token.access_token);
      localStorage.setItem("expirationDate", expirationDate);
      checkAuthTimeout(response?.data?.token?.expires_in)
    }, expirationTime * 1000 - 60000);
   } catch (error) {
    signout();
   }
};

  return {
    registerMerchant,
    loginMerchant,
    forgotPassword,
    newPassword,
    socialMediaLogin,
    logout,
    verifyEmail,
    resendVerificationEmail,
    refreshToken,
    sendOtp,
    loginWithOtp,
    authCheckState,
    checkAuthTimeout
  };
}

export default useAuth;
