import { toast } from 'react-toastify';

/**
 * Handle object conversion to form data
 * @param {object} data
 * @returns
 */
export const jsonToFormData = (data) => {
  let form = new FormData();

  // ------ get keys of data -----
  const objKeys = Object.keys(data);

  // ------- form data values ------
  objKeys.forEach((key) => {
    form.append(key, data[key]);
  });

  return form;
};



export const currencyFormatter = new Intl.NumberFormat("en-US", {
  currency: "UGX",
  style: "currency",
  minimumFractionDigits: 0,
  signDisplay: "auto",
  currencyDisplay: "narrowSymbol",
});

export const notify = (message,type)=>{
  const customId = "custom-id-yes";
  toast(message, {
    toastId: customId,
    type:type
  });

}

export const handleCopy = (text) => {
  navigator.clipboard.writeText(text);
}  

