// -------------- import external dependencies -------------
import React, {
  useState,
  // useEffect
} from "react";
// import styled from "styled-components";
import Cart from '../../components/Cart';

// ----------- import internal dependencies -----------

import BuyerFooter from './components/BuyerFooter';
import Header from './components/Header';

// ----- import assets ------
// import brandLogo from "../../assets/img/logo.png";
// import { ReactComponent as HamburgerIcon } from "../../assets/img/svgs/hamburger.svg";
// ----- import utils ------
// import { sizes } from "../../utils/screenSizes";

// ------------ import external dependencies ------------
// import { Routes, Route } from "react-router-dom";
// import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// import { instance } from "../../api/instance";
// import { toast } from "react-toastify";
// ------  custom hooks ------
import useOrder from "../../hooks/order/useOrder";
// import SearchIcon from "../../assets/img/svgs/search-dark.svg";
import { useShoppingCart } from "../../context/cart/cartContext";

function BuyerLayout({children, icon}) {
    // icon is passed in from buyer routes component

    // ------ component state managers ------
    // const token = localStorage.getItem("accessToken");
    const navigate = useNavigate();
    const [cart, toggleCart] = useState(false);
    const {cartItems} = useShoppingCart();

    // initializing custom hooks
    const { getCart } = useOrder();
    const {
      // isLoading: loadingCart,
      data: cartData,
      // isError: cartLoadingError
      refetch: getCartRefetch
    } = getCart;

    // useEffect(() => {
    //     console.log(cartData);
    //     if(!token) {
    //         // logout();
    //     }
    // }, [])

    // logs out when token is not found
    // const logout = ()=> {
    //     localStorage.clear();
    //     navigate('/signin', {replace: true});
    // }

    // reload cart content
    const refetchCart = ()=> {
      getCartRefetch();
    }

    // checkout from store and proceed to payment
    const checkout = ()=> {
        navigate('/b/store/checkout', {
            state: {
                cart: cartData
            }
        })
        toggleCart(open=>!open);
    }

  return (
    <div>
        <Header cartData={cartItems} toggleCart={toggleCart} cart={cart} storeIcon={icon} />
        {children}
        <BuyerFooter cartData={cartItems} />
        <Cart open={cart} cartData={cartItems} toggleCart={toggleCart} refetch={refetchCart} checkout={checkout} />
    </div>
  );
}

// const ThinLine = styled.div`
//   background-color: var(--grey200);
//   height: 1px;
//   width: 100%;
//   margin-top: 10px;
// `;

export default BuyerLayout;
