// ------------ import external dependencies ----------
import { useMutation, useQuery } from "react-query";

// ------------ import internal dependencies -----------
import { instance } from "../../api/instance";
// import { queryParams } from "../Dashboard/queryparam";
// import { productKeys } from "./query-keys";
import {queryParams} from "./queryparam";

function useOrder(id) {
  /**
   * add to cart
   */
  const addToCart = useMutation((data) => {
    return instance({
      url: "/cart/addToCart",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });

  /**
   * remove item from cart
   */
  const removeFromCart = useMutation((data) => {
    return instance({
      url: "/cart/removeFromCart",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });

  //   get cart content
  const getCart = useQuery(
    "cart",
    async (type) => {
      return instance({
        url: `/cart/buyerCart`,
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
    //   refetchOnWindowFocus: false,
    enabled: false // disable this query from automatically running
    }
  );

    // confirm order
    const confirmOrder = useMutation((data) => {
      return instance ({
        url: '/order/confirm',
        method: 'post',
        data,
        headers : {
          "Content-type": "application/json"
        }
      })
    })

    // track orders
    const trackOrder = useMutation((data) => {
      return instance ({
        url: '/order/track',
        method: 'post',
        data,
        headers : {
          "Content-type": "application/json"
        }
      })
    })
  
  
    //  get buyer address
    const getBuyerAddress = useQuery(
      'buyerAddress',
      async () => {
        return instance({
            url: `/order/buyerAddress`,
            method: "GET",
        });
      },
      {
        retry: (count, { message: status }) => status !== "500",
      //   refetchOnWindowFocus: false,
      enabled: false // disable this query from automatically running
      }
    );
  
    /**
     * add buyer address
     */
    const addAddress = useMutation((data) => {
      return instance({
        url: "/order/buyerAddress/add",
        method: "post",
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    });
  
    /**
     * update address
     */
    const updateAddress = useMutation((data) => {
        return instance({
        url: "/order/buyerAddress/update",
        method: "post",
        data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        });
    });

    // remove single address
    const deleteAddress = useMutation((data) => {
        return instance({
        url: "/order/buyerAddress/remove",
        method: "post",
        data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        });
    });

    // open dispute for orders
    const openDispute = useMutation((data) => {
        return instance({
        url: "/order/openDispute",
        method: "post",
        data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        });
    });

    const preCheckout = useMutation((data) => {
      return instance({
      url: "/order/preCheckout",
      method: "post",
      data,
      headers: {
          "Content-Type": "application/json",
      },
      });
  });
  const orderCheckout = useMutation((data) => {
    const checkoutData = localStorage.getItem('checkoutData');
    const orderDetails = checkoutData? JSON.parse(checkoutData) :null;
    const {access_token} = orderDetails.token;
    
    return instance({
    url: "/order/checkout",
    method: "post",
    data,
    headers: {
        "Content-Type": "application/json",
        "Authorization":`Bearer ${access_token}`
    },
    });
});

const verifyPayment = useQuery(
  'verifyPayment',
  async () => {
    const checkoutData = localStorage.getItem('checkoutData');
    const orderDetails = checkoutData? JSON.parse(checkoutData) :null;
    const token = localStorage.getItem("accessToken");
    instance.defaults.headers.common['Authorization']=`Bearer ${token}`
    return instance({
        url: `/order/payment/callback?reference=${queryParams.reference}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // "Authorization":`Bearer ${access_token}`
      },
    });
  },
  {
    retry:false,
  //   refetchOnWindowFocus: false,
  enabled: false // disable this query from automatically running
  }
);

const getMerchantPickupAddress = useQuery(
  'getMerchantPickupAddress',
  async () => {
    return instance({
        url: `/checkout/getMerchantPickupAddress?merchantID=${queryParams.merchantID}`,
        method: "GET",
    });
  },
  {
    retry:false,
  //   refetchOnWindowFocus: false,
  enabled: false // disable this query from automatically running
  }
);

  return {
    addToCart,
    getCart,
    removeFromCart,
    updateAddress,
    getBuyerAddress,
    deleteAddress,
    addAddress,
    openDispute,
    trackOrder,
    confirmOrder,
    preCheckout,
    orderCheckout,
    verifyPayment,
    getMerchantPickupAddress
  };
}

export default useOrder;
