import React from "react";

const WhatsappIcon = ({ width = "20", height = "20", onClick }) => (
  <svg
    onClick={onClick}
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20L1.41356 14.8669C0.539637 13.3603 0.0811843 11.654 0.0859598 9.9097C0.0859598 4.44392 4.55587 0 10.043 0C12.7077 0 15.2101 1.03137 17.0869 2.90399C18.9685 4.77662 20.0048 7.26711 20 9.91445C20 15.3802 15.5301 19.8242 10.0382 19.8242H10.0334C8.36676 19.8242 6.72874 19.4059 5.2722 18.6169L0 20ZM5.52531 16.8251L5.82617 17.0057C7.09646 17.7567 8.553 18.1511 10.0382 18.1559H10.043C14.6036 18.1559 18.319 14.4629 18.319 9.9192C18.319 7.71863 17.4594 5.65114 15.8978 4.09221C14.3362 2.53327 12.254 1.67776 10.043 1.67776C5.48233 1.673 1.76695 5.36597 1.76695 9.9097C1.76695 11.4639 2.20153 12.98 3.03247 14.2918L3.22827 14.6055L2.39255 17.6426L5.52531 16.8251Z"
      fill="white"
    />
    <path
      d="M0.34375 19.6537L1.70956 14.6965C0.864285 13.2469 0.420159 11.5977 0.420159 9.91515C0.424934 4.63948 4.73725 0.347656 10.0381 0.347656C12.6121 0.347656 15.0238 1.34576 16.8385 3.15184C18.6532 4.95792 19.6513 7.36287 19.6513 9.9199C19.6513 15.1956 15.3342 19.4874 10.0381 19.4874H10.0333C8.42397 19.4874 6.84327 19.0834 5.43926 18.3229L0.34375 19.6537Z"
      fill="#7F56D9"
    />
    <path
      d="M0 20L1.41356 14.8669C0.539637 13.3603 0.0811843 11.654 0.0859598 9.9097C0.0859598 4.44392 4.55587 0 10.043 0C12.7077 0 15.2101 1.03137 17.0869 2.90399C18.9685 4.77662 20.0048 7.26711 20 9.91445C20 15.3802 15.5301 19.8242 10.0382 19.8242H10.0334C8.36676 19.8242 6.72874 19.4059 5.2722 18.6169L0 20ZM5.52531 16.8251L5.82617 17.0057C7.09646 17.7567 8.553 18.1511 10.0382 18.1559H10.043C14.6036 18.1559 18.319 14.4629 18.319 9.9192C18.319 7.71863 17.4594 5.65114 15.8978 4.09221C14.3362 2.53327 12.254 1.67776 10.043 1.67776C5.48233 1.673 1.76695 5.36597 1.76695 9.9097C1.76695 11.4639 2.20153 12.98 3.03247 14.2918L3.22827 14.6055L2.39255 17.6426L5.52531 16.8251Z"
      fill="url(#paint0_linear_3116_33704)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.55362 5.76454C7.36737 5.35104 7.17157 5.34154 6.99488 5.33678C6.85161 5.33203 6.68447 5.33203 6.51733 5.33203C6.35018 5.33203 6.08275 5.39382 5.85352 5.64097C5.6243 5.88811 4.98438 6.48697 4.98438 7.70846C4.98438 8.92519 5.8774 10.1039 6.00157 10.2702C6.12573 10.4366 7.72554 13.0174 10.2518 14.0107C12.353 14.8377 12.7828 14.6714 13.2365 14.6286C13.6902 14.5858 14.7074 14.0298 14.9175 13.4499C15.1229 12.8701 15.1229 12.3758 15.0608 12.2712C14.9987 12.1666 14.8315 12.1048 14.5832 11.9813C14.3349 11.8577 13.1124 11.2588 12.8831 11.1733C12.6539 11.0925 12.4868 11.0497 12.3244 11.2969C12.1572 11.544 11.6797 12.1001 11.5364 12.2664C11.3932 12.4328 11.2451 12.4518 10.9968 12.3282C10.7485 12.2047 9.94617 11.9432 8.99583 11.0972C8.25562 10.4413 7.75419 9.62861 7.61092 9.38146C7.46766 9.13431 7.5966 9.00123 7.72076 8.87766C7.8306 8.76834 7.96909 8.58774 8.09326 8.44515C8.21742 8.30256 8.2604 8.198 8.34158 8.03165C8.42277 7.8653 8.38456 7.72272 8.32248 7.59914C8.2604 7.48032 7.77329 6.25408 7.55362 5.76454Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3116_33704"
        x1="10.0019"
        y1="19.9981"
        x2="10.0019"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F9F9F9" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>
);

export default WhatsappIcon;
