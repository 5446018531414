// import React, {useState, useEffect} from 'react';

// importing external dependencies
// import { toast } from "react-toastify";

// importing assets
import CloseIcon from "../../assets/img/svgs/close-white.svg";
import CloseGreyIcon from "../../assets/img/svgs/close.svg";
import PlusIcon from "../../assets/img/svgs/plus.svg";
import MinusIcon from "../../assets/img/svgs/minus.svg";
// ------  custom hooks ------
// import useOrder from "../../hooks/order/useOrder";
// ------- import utils helper -----
// import { resFormatter } from "../../utils/responseMsg";
import { useShoppingCart } from '../../context/cart/cartContext';
import { currencyFormatter } from '../../utils/helper';

function Cart({open, cartData, toggleCart, checkout}) {
    // useEffect(() => {
    //     //refetch();
    // }, [])
    const {addItemToCart,decreaseCartQuantity,removeFromCart,getItemQuantity,total} = useShoppingCart();
    // initializing custom hooks
    //const { removeFromCart, addToCart } = useOrder();

    // const [removingItem, setRemovingItem] = useState(false);
    // const removeItem = async (productId, quantity)=> {
    //     setRemovingItem(true);
    //     const body = {
    //         cart_id : cartData?.data?.cart.cart_id,
    //         productID : productId,
    //         quantity : quantity,
    //     }
    //     try {
    //         const { data: response } = await removeFromCart.mutateAsync(body);
    //         console.log(response);
    //         toast.success('removed item');
    //         setRemovingItem(false);
    //        // refetch();
    //         // console.log(response);
    //         // cb();
    //     } catch (err) {
    //         console.log(err);
    //         // const errMsg = JSON.parse(err?.response?.data?.message) || err.message;
    //         toast.error(resFormatter(err?.response ?? err));
    //         setRemovingItem(false);
    //     }
    // }

    // const [addingProduct, setAddingProduct] = useState(false);
    // add product to cart
    // const addProductToCart = async (productId, quantity)=> {
        // setAddingProduct(true);
        // const body = {
        //     cart_id : cartData?.data?.cart.cart_id,
        //     productID : productId,
        //     quantity : quantity,
        // }
        // try {
            // const { data: response } = await addToCart.mutateAsync(body);
            // console.log(response);
            // toast.success('Added item');
            // setAddingProduct(false);
            // refetch();
            // console.log(response);
            // cb();
        // } catch (err) {
        //     console.log(err);
        //     setAddingProduct(false);
        //     toast.error(resFormatter(err?.response ?? err));
        // }
    // }

    return (
        <>
        {open ? (
            <div className="flex fixed h-screen w-full justify-end" style={{top: '0', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: '200'}}>
                <div className="flex flex-col bg-white-100 h-100 w-full md:w-1/3 overflow-y-auto">
                    <div className="flex border-0 border-b-2 border-solid border-grey-200 px-2 py-4 justify-between">
                        <p className="text-lg font-bold text-black-100">Shopping cart</p>
                        <div className="bg-grey-500 p-3 border-2 border-solid border-grey-200 justify-center rounded-lg cursor-pointer"
                            onClick={()=>toggleCart(!open)}>
                            <img src={CloseIcon} alt="close" className="text-sm" style={{color: 'white'}} />
                        </div>
                    </div>
                    {/* items */}
                    <div className="px-2 mb-4">
                        {cartData?.length === 0 ? (
                            <div className="text-center w-full">
                                <p className="text-base font-semibold mt-20">Cart is empty</p>
                            </div>
                        ) : (null)}
                        {cartData?.map(item => (
                            <div key={item?.id} className="flex h-32 w-full border-0 border-b-2 border-solid border-grey-200 py-2">
                                <img src={item?.image_url} className="h-full w-1/4 object-contain" alt="cart item"/>
                                <div className="flex flex-col w-3/4 pl-2">
                                    <div className="flex w-full  justify-between">
                                        <p>{item?.productName}</p>
                                        <img src={CloseGreyIcon} alt="close" className="text-sm cursor-pointer" style={{color: 'white'}}
                                            onClick={()=> removeFromCart(item.id)}
                                         />
                                    </div>
                                    <div className="flex w-full mt-auto">
                                        <div className="flex border-grey-200 border-solid border-2 rounded text-grey-100 text-sm w-1/2 mt-auto">
                                            <div className="flex place-content-center cursor-pointer border-0 border-grey-200 border-solid border-r-2 p-2 basis-2/6"
                                                onClick={()=> decreaseCartQuantity(item.id)}
                                            >
                                                <img src={MinusIcon} alt="add" className="basis-2 my-auto" />
                                            </div>
                                            <div className="flex place-content-center border-0 border-grey-200 border-solid border-r-2 p-2 basis-2/6">
                                                {item?.quantity}
                                            </div>
                                            <div className="flex place-content-center cursor-pointer p-2 basis-2/6"
                                                onClick={()=> addItemToCart(item)}
                                            >
                                                <img src={PlusIcon} alt="remove" className="basis-2 my-auto" />
                                            </div>
                                        </div>
                                        <div className="flex w-1/2 items-center justify-end">
                                            <p className="text-lg font-bold truncate">{currencyFormatter.format(item?.total_cost)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="bg-grey-200 p-4 flex flex-col mt-auto w-full">

                        <div className="flex text-base w-full justify-between mt-auto h-10">
                            <span className="text-base">Subtotal</span>
                            <span className="text-base">{currencyFormatter.format(total?Number(total):0)}</span>
                        </div>
                        <div className="flex text-base w-full justify-between h-10">
                            <span className="text-base">Shipping</span>
                            <span className="text-base">{currencyFormatter.format(cartData?.data?.cart?.shipping?cartData?.data?.cart?.shipping:0)}</span>
                        </div>
                        <div className="flex text-base w-full justify-between mt-auto h-10">
                            <button className="btn flex bg-purple-600 border-0 w-full place-content-center text-white-100 text-base py-2 rounded-lg cursor-pointer"
                            onClick={checkout} disabled = {cartData?.length > 0 ? false : true} >
                                Checkout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ) : (null)}
        </>
    )
}

export default Cart
