// ----------- import external dependencies ------------
import styled from "styled-components";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// ---------- import internal dependencies ----------
// import SearchInput from "../../../components/SearchInput";
// ------ import assets -------
import brandLogo from "../../../assets/img/logo.png";
import { ReactComponent as HomeIcon } from "../../../assets/img/svgs/home.svg";
import { ReactComponent as OrderIcon } from "../../../assets/img/svgs/order.svg";
import { ReactComponent as ReportIcon } from "../../../assets/img/svgs/report.svg";
import { ReactComponent as ProductsIcon } from "../../../assets/img/svgs/products.svg";
import { ReactComponent as FundsIcon } from "../../../assets/img/svgs/funds.svg";
import { ReactComponent as IssuesIcon } from "../../../assets/img/svgs/issues.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/img/svgs/logout.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/img/svgs/settings.svg";
import { ReactComponent as ActivityIcon } from "../../../assets/img/svgs/activity.svg";
import Avatar from "../../../assets/img/blank-profile-picture.webp";
// ------ import utils ------
import { sizes } from "../../../utils/screenSizes";

// ------ hooks ------
import /*useProfileInfo,*/ {useGetUserProfile} from "../../../hooks/user/useProfileInfo";
// import useAuth from "../../../hooks/user/useAuth";

import {useEffect, /*useState*/} from 'react';

function Sidebar({ close,setOpen }) {
  const navigate = useNavigate();
  // console.log(user)

  // initializing custom hooks
  // const { getUserProfile } = useProfileInfo();
  const {
    // isLoading: loadingProfile,
    data: profileData,
    // isError
  } = useGetUserProfile();
  // const { logout } = useAuth();

  useEffect(() => {
  }, [profileData])

  // ------- routes config ------
  const routes = [
    {
      path: "/merchant/dashboard",
      icon: HomeIcon,
      title: "Home",
    },
    {
      path: "/merchant/orders",
      icon: OrderIcon,
      title: "Orders",
    },
    {
      path: "/merchant/reports",
      icon: ReportIcon,
      title: "Reports",
    },
    {
      path: "/merchant/products",
      icon: ProductsIcon,
      title: "Products",
    },
    {
      path: "/merchant/funds",
      icon: FundsIcon,
      title: "Funds",
    },
    {
      path: "/merchant/issues",
      icon: IssuesIcon,
      title: "Issues",
    },
    {
      path: "/merchant/activities",
      icon: ActivityIcon,
      title: "Activity",
    },
    {
      path: "/merchant/subscription",
      icon: ProductsIcon,
      title: "Subscription",
    },
  ];

  const signout = async ()=> {
    localStorage.clear();
      toast.success('You have successfully logged out.');
      navigate("/signin", {replace: true,state:{from:null}})
  }

  // const [showText, setShowText] = useState(false);

  // const toggleLogoutText = (state)=> {
  //   setShowText(!showText);
  // }

  return (
    <>
      <SidebarWrapper>
        <div className="h-[inherit] relative overflow-auto overscroll-contain">
          <div
            className="flex justify-end cursor-pointer close-menu"
            onClick={close}
          >
            <span className="text-grey-700 text-2xl">&times;</span>
          </div>
          <div>
            <img src={brandLogo} alt="Mudaala brand logo" />
           {/* <div className="mt-4">
              <SearchInput />
           </div>*/}
          </div>
          <nav>
            <ul>
              {routes.map((route, ind) => (
                <li key={ind}>
                  <NavLink
                    to={route.path}
                    className={({ isActive }) =>
                      isActive ? "menu-active" : "menu-link"
                    }
                  >
                    <route.icon />
                    <span>{route.title}</span>
                  </NavLink>
                </li>
              ))}
              <li>
              <Link to="settings" className="menu-link ">
                <SettingsIcon />
                <span>Settings</span>
              </Link>
              </li>
              <li>
              <span onClick={signout} className="flex pl-3">
              <LogoutIcon />
                <span className="text-red-500 pt-[5px] pl-[2px] text-md cursor-pointer">Logout</span>
              </span>
              </li>
            </ul>
          </nav>
          <div className="nav-bottom">
            <ThinLine />
            <div className="flex items-center pt-2 gap-2">
              <img src={profileData?.data?.userProfile?.store ? profileData.data.userProfile.store.store_icon : Avatar} alt="User profile" style={{height: '30px', width: '30px', borderRadius: '15px'}} onClick={()=> navigate("/merchant/settings")} />
              <div>
                <div className="flex w-full items-center ">
                  <span className="text-xs block text-grey-900">
                    {profileData?.data?.userProfile?.name}
                  </span>
                </div>
                <span className="text-xs block text-grey-500 ">
                  {profileData?.data?.userProfile?.email}
                </span>
              </div>
            </div>
          </div>
        </div>
      </SidebarWrapper>
    </>
  );
}

export default Sidebar;

const SidebarWrapper = styled.aside`
  height: 100vh;
  width: 250px;
  position: fixed;
  top: 0;
  background: var(--white100);
  padding: 2rem 1.25rem;
  border-right: 1px solid var(--grey200);
  transition: all 0.3s ease-in-out;
  z-index: 1;

  & ul {
    list-style: none;
    padding: 0;

    & li {
      margin-bottom: 0.4rem;
    }
  }

  & .nav-bottom {
    position: absolute;
    width: 100%;
    padding-bottom: 2rem;
  }

  & .close-menu {
    display: none;
  }

  @media screen and (max-width: ${sizes.tablet}) {
    & .close-menu {
      display: flex;
    }
  }
`;

const ThinLine = styled.div`
  background: var(--grey200);
  height: 1px;
  width: 100%;
`;
