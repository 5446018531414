import React, {
  useState,
  // useEffect,
  useRef} from 'react';
// import Cart from '../../../components/Cart';
import { toast } from "react-toastify";

// ------------ import external dependencies ------------
import {
  // Link,
  useNavigate,
  // useParams,
  useLocation
} from "react-router-dom";

// ------------ import assets ------------
import BrandLogo from "../../../assets/img/logo.png";
// import ShareIcon from "../../../assets/img/svgs/share.svg";
import SearchIcon from "../../../assets/img/svgs/search-dark.svg";
import CloseIcon from "../../../assets/img/svgs/close.svg";
import UserIcon from "../../../assets/img/svgs/user.svg";
import CartIcon from "../../../assets/img/svgs/cart.svg";
// import {ReactComponent as MenuIcon } from "../../../assets/img/svgs/menu-2.svg";
// import MarketplaceSearchInput from 'components/MarketplaceSearchInput';
// ------  custom hooks ------
// import useOrder from "../../../hooks/order/useOrder";
import SearchInput from 'components/SearchInput';
// import useCategory from 'hooks/category/usecategory';

function Header({cartData, toggleCart, cart, storeIcon}) {
    const navigate = useNavigate();
    // const location = useLocation();
    const {pathname,search} = useLocation();
    // const [menu, toggleMenu] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(false);
    // const searchParams = new URLSearchParams(location.search);
    // const home = localStorage.getItem('home')?localStorage.getItem('home'):'/';
    // const merchantCode = searchParams.get('code');

    const changeSearchInput = ()=>{
        setShowSearchInput(prev=>!prev);
    }

    let timeout = useRef();
    const handleSearch = (event)=> {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        navigate(`${pathname}${search?search.split('&')[0]:''}&keyword=${event.target.value}`)
        //search(event.target.value);
      }, 2000);
    }

   //buyer/merchant-store/search?q=me&code=inaqeeb94-1673000569
    // checkout from store and proceed to payment
    const checkout = ()=> {
        if (cartData?.length > 0) {
            navigate('/b/store/checkout', {
                state: {
                    cart: cartData
                }
            })
        }
        else {
            toast.info("No items in cart");
        }
    }

    const nav = (route)=> {
        navigate(route);
    }





    return (
        <div className="py-7 px-5 md:px-20 border-0 border-b-2 border-solid border-grey-200 flex">
            <div className="w-1/2 md:w-1/4 flex">
                {/* <img src={SampleLogo} alt="sample logo" className="mr-auto text-sm" style={{height: '32px', width: '32px'}} /> */}
                {/* <img src={storeIcon ? storeIcon : BrandLogo} alt="sample logo" className="mr-auto text-sm" style={{height: '30px', width: '60px'}} /> */}
                <a href="https://mudaala.ug">
                <img src={storeIcon ? storeIcon : BrandLogo} alt="sample logo" className="mr-auto text-sm object-contain" style={{width: "90.42px",height: "32px"}} />
                </a>
            </div>
            <div className="w-1/2 hidden md:flex">
                <div className="mx-auto flex space-x-10">
                <a href='https://mudaala.ug' className="basis-2 text-md text-grey-600 my-auto cursor-pointer">Marketplace</a>
                    <a href="https://mudaala.ug" className="basis-2 text-md text-grey-600 my-auto cursor-pointer" >
                        Home
                    </a>
                    <span className="basis-2 text-md text-grey-600 my-auto cursor-pointer" onClick={checkout}>Checkout</span>
                    <a href='https://mudaala.ug/support' className="basis-2 text-md text-grey-600 my-auto cursor-pointer">Support</a>
                </div>
            </div>

            <div className="w-full md:w-1/4 flex">
            {
                showSearchInput?(
                    <div className="w-full ml-auto flex space-x-5">

              <SearchInput onChange={handleSearch} />
              <img src={CloseIcon} alt="sample logo" onClick={changeSearchInput} className="basis-2 my-auto cursor-pointer" style={{height: '20px', width: '20px'}} />
            </div>

                ):(
                    <div className="ml-auto flex space-x-5 md:space-x-10">
                    <img src={SearchIcon} alt="sample logo" onClick={changeSearchInput} className="basis-2 my-auto cursor-pointer" style={{height: '20px', width: '20px'}} />
                    <span className="flex cursor-pointer" onClick={()=>toggleCart(!cart)}>
                        <img src={CartIcon} alt="sample logo" className="basis-2 my-auto" style={{height: '20px', width: '20px'}} />

                        {cartData?.length > 0 ? (
                            <span className="flex p-px bg-red-600 place-content-center text-center justify-center" style={{height: '20px', width: '20px', borderRadius: '10px'}}>
                                <span className="my-auto text-xs text-white-100">{cartData?.length}</span>
                            </span>
                        ):(null)}

                      </span>
                    <img src={UserIcon} onClick={()=> nav('/b/account')} alt="sample logo" className="basis-2 my-auto cursor-pointer" style={{height: '20px', width: '20px'}} />
                </div>
                )
            }


            </div>

        </div>

    )
}

export default Header
