import React from 'react';
import styled from "styled-components";
import {Link, useNavigate } from "react-router-dom";
import { MtnIcon } from 'components/vectors';
import { toast } from "react-toastify";

// ------------ import assets ------------
// import BrandLogo from "../../../assets/img/logo.png";
// import ShareIcon from "../../../assets/img/svgs/share.svg";
import SearchIcon from "../../../assets/img/svgs/search-dark.svg";
// import UserIcon from "../../../assets/img/svgs/user.svg";
// import CartIcon from "../../../assets/img/svgs/cart.svg";

function BuyerFooter({cartData}) {
    const home = localStorage.getItem('home')?localStorage.getItem('home'):'/';
    const navigate = useNavigate();
    const checkout = ()=> {
        if (cartData?.length > 0) {
            navigate('/b/store/checkout', {
                state: {
                    cart: cartData
                }
            })
        }
        else {
            toast.info("No items in cart");
        }
    }
    return (
        // <div className="w-full mt-[20%]">
        <div className="w-full mt-20">
            <div className="px-6 md:px-20 w-full">
                <ThinLine />
            </div>
            <div className="flex px-6 md:px-40 py-5">
                <div className="mx-auto flex space-x-10">
                    <Link to={home}>
                    <span className="basis-2 text-md text-grey-600 my-auto cursor-pointer">Home</span>
                    </Link>
                    <span className="basis-2 text-md text-grey-600 my-auto cursor-pointer" onClick={checkout}>Checkout</span>
                    <Link to={'/b/support'}>
                    <span className="basis-2 text-md text-grey-600 my-auto">Support</span>
                    </Link>
                </div>
            </div>
            <div className="px-6 md:px-20 w-full">
                <ThinLine />
            </div>
            <div className="py-5 px-6 md:px-20 md:py-8 flex flex-col md:flex-row w-full justify-center items-center ">
                <div className="flex flex-col ">
                    <div className="flex ml-0  gap-1">
                        <Link to="/privacy-policy"><span className="text-grey-600">Privacy Policy</span></Link>
                        <span className="text-grey-600">.</span>
                        <Link to="/terms-and-condition"><span className="text-grey-600">Terms of Service</span></Link>
                    </div>
                </div>

                <div className="flex my-1 ml-0 md:ml-auto order-1 md:order-2">
                  {/* <p className="text-base text-grey-600">Powered by</p>
                    <div className=" px-2"><MtnIcon className='h-7 w-14 text-grey-600'/></div> */}
                </div>

            </div>
        </div>
    )
}

const ThinLine = styled.div`
  background-color: var(--grey200);
  height: 1px;
  width: 100%;
  margin-top: 10px;
`;

export default BuyerFooter;
