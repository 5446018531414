// -------------- import external dependencies -------------
import { Suspense, useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from 'react-router-dom';

// ----------- import internal dependencies -----------
import Sidebar from "./components/Sidebar";
// ----- import assets ------
import brandLogo from "../../assets/img/logo.png";
import { ReactComponent as HamburgerIcon } from "../../assets/img/svgs/hamburger.svg";
// ----- import utils ------
import { sizes } from "../../utils/screenSizes";
// import useAuth from "hooks/user/useAuth";

function DashboardLayout({ children }) {
  // ------ component state managers ------
  // const { authCheckState } = useAuth();
  const token = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const userInfo = user? JSON.parse(user):null;
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [screenSize,setScreenSize] = useState(undefined);


  useEffect(() => {
    if(!token || userInfo.account_type!="Merchant") {
      logout();
    }else if(userInfo?.email_verified === null){
      navigate("/signup-success");
    }
  }, [])




  // logs out when token is not found
  const logout = ()=> {
    localStorage.clear();
    navigate('/signin', {replace: true,state:{from:location.pathname}});
  }

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 768) {
      setOpen(false);
    }else{
      setOpen(true);
    }
  }, [screenSize]);

  return (

        <main>
          {open && <Sidebar close={() => setOpen(false)} />}
          <Suspense
          fallback={
            <div className="h-screen w-screen flex justify-center items-center">
              <div className="h-32 w-32 animate-spin" style={{border: "16px solid #f3f3f3", borderTop: "16px solid #7F56D9", borderRadius: "50%" }}>
              </div>
            </div>
          }
        >
          <ContentWrapper>
            <MobileWrapper className="mobile-heading">
            <Link to="/">
              <img src={brandLogo} alt="Mudaala brand logo" />
              </Link>
             <div className="cursor-pointer" onClick={() => setOpen(prev=>!prev)}>
                <HamburgerIcon />
              </div>

            </MobileWrapper>
            {children}
          </ContentWrapper>
        </Suspense>
        </main>


  );
}

const ContentWrapper = styled.div`
  margin-left: 250px;
  padding: 1.5rem;

  @media screen and (max-width: ${sizes.tablet}) {
    margin-left: 0;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey200);
  // padding: 1.5rem 1rem;
  margin-bottom: 1rem;

  @media screen and (max-width: ${sizes.tablet}) {
    display: flex;
  }
`;

export default DashboardLayout;
