// ------------- import external dependencies ------------
import styled from "styled-components";


// --------------- import internal dependencies -----------------

import { sizes } from "../../utils/screenSizes";
// ------ import images -------
import AuthBg from "../../assets/img/Section.png";

function AuthLayout({ page, children, imgDesc = "Authentication banner" }) {
  return (
    <>
      
        <main>
          <section>
            <Wrapper>
              <div>{children}</div>
              <div className="hidden md:block ">
                <img src={AuthBg} alt={imgDesc} className=" w-full h-screen" />
              </div>
            </Wrapper>
          </section>
        </main>
      
    </>
  );
}



export default AuthLayout;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;

  & > div {
    width: 100%;

    @media screen and (max-width: ${sizes.mobileL}) {
      width: 100%;

      &:nth-child(2n) {
        display: none;
      }
    }
  }
`;
