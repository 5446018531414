import axios from "axios";
import { toast } from "react-toastify";

// let url;

// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
//   url = process.env.REACT_APP_STAGING_URL;
// } else {
//   url = process.env.REACT_APP_LIVE_URL;
// }

export const instance = axios.create({

  baseURL: "https://api.mudala.co.ug/api", 
  //baseURL: "https://dev.api.mudala.co.ug/api",
  // timeout: 1000,
});
 
const signout = ()=> {
  localStorage.clear();
  toast.success('Session expired');
}

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("accessToken") ?? "";
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  console.log(error);
  handleError(error);
  // Do something with response error
  return Promise.reject(error);
});

const handleError = async(error)=> {
  if (error.response.status === 401 && error.response.data.message === "Invalid or expired or no token") {
   
    
    // console.log(window.location);
    // return
    if(!window.location.pathname.includes("/buyer/merchant-store")) {
      localStorage.setItem("returnPath", window.location.pathname);
      //window.location.replace("/signin");
    }
    // if user tries to ad to cart
    if (error.response.config.url === "/cart/addToCart") {
      localStorage.setItem("returnPath", window.location.pathname + window.location.search);
      //window.location.replace("/signin");
    }
  }
}

export const socialInstance = axios.create({
  baseURL: "https://api.mudala.co.ug",
  // timeout: 1000,
});

socialInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
