// ----------- import external dependencies -----------
import React from "react";
import * as ReactDOMClient from 'react-dom/client';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'


// -------------- import internal dependencies -------------
import App from "./App";
import { ShoppingCartProvider } from "./context/cart/cartContext";
import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider, QueryClient } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";


Bugsnag.start({
  apiKey: 'a7d4a7ed0a29c0f649a45725a3e4eaa9',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, { message: status }) => status !== "500",
    },
  },
});

const container = document.getElementById('root');

const AppLayout = (
  <React.StrictMode>
    <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <ShoppingCartProvider>
        <GoogleOAuthProvider clientId="1085614773661-e36tjkli9oen6laocn1hra8anuedhn0f.apps.googleusercontent.com">
          <App />
        </GoogleOAuthProvider>
      </ShoppingCartProvider>
    </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// Create *and* render a root with hydration.
const root = ReactDOMClient.hydrateRoot(container, AppLayout);

// const rootElement = document.getElementById("root");

// if (root.hasChildNodes()) {
//   root.hydrate(AppLayout, root);
// } else {
//   ReactDOM.render(AppLayout, root);
// }

// root.render(
//     <QueryClientProvider client={queryClient}>
//     <ShoppingCartProvider>
//     <GoogleOAuthProvider clientId="1085614773661-e36tjkli9oen6laocn1hra8anuedhn0f.apps.googleusercontent.com">
//     <App />
//     </GoogleOAuthProvider>
//     </ShoppingCartProvider>
//     </QueryClientProvider>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
